<template>
  <div class="dashboard">
    <h1 class="white--text">Welcome to the dashboard.</h1>

  </div>
</template>

<script>
export default {
  name: "Dashboard",
  components: {  },
  mounted() {

  },
};
</script>
